.flex-container {
    display: flex;
    align-items: center;
  }
  
  .flex-child-left {
    margin-right: 20px;
    max-width: 240px; 
    min-width: 100px; 
  } 
  
  .flex-child-right {
    flex: 1 0;  
    width:100%;
    flex-grow: 1;
  }  