.flex-container {
  display: flex;
  align-items: flex-start;
}

.flex-child-left {
  margin-right: 20px;
  max-width: 240px;  
} 

.flex-child-right {
  flex: 1 0;  
  width:100%;
  vertical-align: top;
  flex-grow: 1;
}  