.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.about,
.services,
.products,
.chat,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.about {
  background-image: url('/public/images/img-12.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 50px;
}

.workspace {
  background-image: url('/public/images/img-dna-double-helix_2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
  height: 100vh;
}

.services {
  background-image: url('/public/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/public/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/public/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.search-workspace-ct-parent {
  /* border: 1px solid black; */
  /* margin: 1rem; */
  /* padding: 2rem 2rem; */
  text-align: left;
}
.search-workspace-ct-child {
  display: inline-block;  
  padding: 1rem 1rem;
  vertical-align: middle;
}

.generic-workspace-parent {
  /* border: 1px solid black; */
  width: 100%;
  margin: 1rem;
  padding: 2rem 2rem;
  text-align: left;
}
.generic-workspace-child {
  display: inline-block;  
  /* padding: 1rem 1rem; */
  vertical-align: middle;
}

.rs-col {
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 5px;
}