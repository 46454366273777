.background {
  background: url('../../public/images/about_rare_disease.webp');
  background-repeat: no-repeat;
  background-size: contain;
  /* background-position: right top; */
  height: 400px;
  /* width: 100%; */
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  object-fit: fill; 
  /* padding-top: 7vh;   */
  margin: 100px;
}

.auto-resizable-iframe {
  max-width: 420px;
  margin: 0px auto;
}

.auto-resizable-iframe > div {
  position: relative;
  padding-bottom: 75%;
  height: 0px;
}

.auto-resizable-iframe iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 768px){
  .description-container{
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 768px){
  .description-container{
    align-items: center;
    flex-direction: row;
  }
}

.description-container{
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  /* border: 3px solid dimgray; */
  fill-opacity: 100%;
  /* fill: grey; */
  border-radius: 10px;
  /* background-color: rgba(128,128,128, 0.8); */
  display: flex;
  flex: 1;  
  /* justify-content: center; */
  align-items: left;
}

.description-container > h1 {
  padding: 5%;
  color: #000;
  font-size: 16px;
  padding-bottom: 5%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.description-container > h2 {
  color: #dadbe3;
  font-size: 16px;
  padding-left: 3%;
  padding-right: 3%;
  text-indent: 45px;
  font-family: "Lucida Sans", Ariel, sans-serif;
  padding-bottom: 2%; 
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.description-container > p {
  margin-top: 8px;
  color: black;
  font-size: 16px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.description-text > p {
  margin-top: 8px;  
  color: black;
  font-size: 16px;
  padding-left: 0px !important;
  padding-top: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;  
}
